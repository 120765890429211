<template>
    <div>
        <!--<PageHeader :titulo="titulo"/>-->
        <titulo-seccion>
            <template slot="area-rutas">
                <a href="/">Inicio</a> / <span class="active">RSS</span>
            </template>
            <template slot="area-titulo">
                <h3 class="text-center">RSS</h3>
            </template>
        </titulo-seccion>
        <section class="noticias">
            <div class="container">
                <div class="row">
                    <h4>Secciones</h4>
                    <template>
                        <b-list-group flush>
                            <b-list-group-item v-for="(item,index) in items" :key="index">
                                <b-row>
                                <b-col cols="12" md="4">
                                    {{item.nombre}}
                                </b-col>
                                <b-col cols="10" md="6">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-rss-fill me-2" viewBox="0 0 16 16">
                                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm1.5 2.5c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1 0-2zm0 4a6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1 0-2zm.5 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                                    </svg>
                                    <a :href="baseUrl+'/rss/feed.html/'+item.id" target="_blank">{{baseUrl+'/rss/feed.html/'+item.id}}</a>
                                </b-col>
                                <b-col cols="2" md="2">
                                    <b-badge :href="baseUrl+'/rss/feed.html/'+item.id" target="_blank" variant="primary">
                                        XML
                                    </b-badge>
                                </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-list-group>
                    </template>

                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BListGroup, BListGroupItem, BRow, BCol, BBadge  } from 'bootstrap-vue'
import TituloSeccion from '@/views/secciones/TituloSeccion.vue'
export default {
    components:{
        TituloSeccion,
        BListGroup,
        BListGroupItem,
        BRow,
        BCol,
        BBadge 
    },
    directives: { 

    },
    data(){
        return{
            items:[],
            baseUrl:axiosIns.defaults.baseURL,
        }
    },
    metaInfo: {
        title: 'Ministerio Público',
        titleTemplate: '%s | RSS',
        htmlAttrs: {
        lang: 'es-ES'
        },
        meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'RSS gestion de recursos XML' },
        ]
    },
    created(){       
        this.listarRss();
    },
    watch: {

    },

    methods: {
        listarRss() {
            var url= '/front/tipo-entradas';
            axiosIns.get(url)
            .then(res => {
                this.items = res.data
            })
            .catch(err =>{
                console.log(err);
            });
        },
  },

}
</script>

<style>
.cursor{
    cursor: pointer;
}
#scrollspy-example {
    position: absolute;
    height: 100%;
    overflow-y: scroll;   
}
.media {
    display: flex;
    align-items: flex-start;
}
.media-aside {
    display: flex;
    margin-right: 1rem;
}
.align-self-start {
    align-self: flex-start!important;
}
.media-list .media {
    padding: 1.25rem;
    margin-top: 0;
}
.media-bordered .media:not(:first-child) {
    border-top: 1px solid #ebe9f1;
}
.card-title{
    font-size: 14px;
}
.box-imagen {
  height: 15rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
@media (max-width: 700px) {
  .box-imagen {
    height: 5rem;
  }
  .embed-responsive-4by3 {
      height: calc(var(--vh, 1vh) * 100 - 10rem) !important;
  }
}
</style>
